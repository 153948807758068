import { FC } from "react";
import { CreateButton, Datagrid, EditButton, List, ShowButton, TextField, TopToolbar } from "react-admin";

interface Props {}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const LawFirmsList: FC<Props> = (props) => {

    return (
        <List {...props} pagination={false} actions={<ListActions/>}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={'id'} />
                <TextField source={'name'} />
                <TextField source={'prospect_status.name'} label={'Status'}/>
                <TextField source={'address_line1'} />
                <TextField source={'city'} />
                <TextField source={'state'} />
                <TextField source={'postal_code'} />
                <ShowButton label={'View'}/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}

export default LawFirmsList